import React from 'react';
import './App.css';
import Profile from './components/Profile/Profile';
import { ProfileProps } from './components/Profile/Profile';

function App() {
  const people: ProfileProps[] = [
    {
      name: "Adam Zdunek",
      url: "https://www.linkedin.com/in/adam-zdunek-394a68130/",
      description: "Backend developer, Azure certified",
      image: "adam"
    },
    {
      name: "Agata Zdunek",
      url: "https://www.linkedin.com/in/agata-zdunek-a9a605141/",
      description: "Data engineer, Azure certified",
      image: "agata"
    },
    {
      name: "Beata Zdunek",
      url: "https://www.linkedin.com/in/beata-zdunek-179544171/",
      description: "Project specialist, translator",
      image: "beata"
    }
  ]

  return (
    <div className="App">
      <div className="Profiles">
        {people.map(function (person) {
          return <Profile key={person.name} {...person} />
        })}
      </div>
      <p className="Footer-main">Something exciting will be here in the future!</p>
    </div>
  );
}

export default App;
