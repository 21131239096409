import { FC } from 'react';
import styles from './Profile.module.css';
import adam from '../../images/adam.jpg'
import agata from '../../images/agata.jpg'
import beata from '../../images/beata.jpg'

export interface ProfileProps {
  name: string,
  url: string,
  description: string,
  image: string,
}

const getImageByName = (imageName: string) => {
  switch(imageName) {
    case "adam":{return adam}
    case "agata":{return agata}
    case "beata":{return beata}
    default: {return undefined}
  };
}
const Profile: FC<ProfileProps> = (props: ProfileProps) => (  
  <div className={styles.Profile}>
      <img src={getImageByName(props.image)} className={styles.Image} alt="" />
      <p className={styles.Name}>{props.name}</p>
      <p className={styles.Description}>{props.description}</p>
      <a className={styles.Link} href={props.url}>Visit my LinkedIn profile</a>
    </div>
);

export default Profile;
